<template>
  <div>
    <Menu/>
    <router-view></router-view>
  </div>
</template>
<script>
import Vue from 'vue';
import BootstrapVue from "bootstrap-vue";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Menu from '@/components/Menu/Partenaire';
Vue.use(BootstrapVue)
export default {
  name: "",
  components: {
    Menu
  },
  data: () => ({

  }),
  beforeCreate() {
    document.getElementsByTagName('body')[0].className = "body-crm";
  },
}
</script>
<style>
.card{
  border-radius: 0.50rem;
}
.card-custom{
  padding: 50px
}
thead .sr-only{
  display: none;
}
thead th div {
  color: var(--bs-blue)!important;
}
.body {
  color: var(--bs-blue)!important;
}
</style>
